import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useRef, useState } from 'react';

import { HeaderTheme } from 'holded/components/Layout/HeaderTheme';
import { HeaderSection, Page } from 'holded/modules/cms/domain/page';
import Button from 'holded/modules/cms/ui/shared/components/Button';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
import LocaleSwitch from 'holded/modules/cms/ui/shared/components/LocaleSwitch';
import Menu, { MenuState } from 'holded/shared/ui/components/Menu/Menu';
import { useDevice } from 'holded/shared/ui/hooks/useDevice';

import navbarStyles from './Header.module.scss';

const initialMenuState = { name: '', state: false };

type HeaderProps = {
  header: HeaderSection;
  pageData: Page;
};

type HeaderMobileProps = {
  header: HeaderSection;
  handleMenuState: (menu: MenuState) => void;
  menuState: MenuState;
  pageData: Page;
  dark: boolean;
  mobileNavHeight?: number;
};

const HeaderMobile = ({ header, handleMenuState, menuState, pageData, dark, mobileNavHeight }: HeaderMobileProps) => {
  const isMobile = useDevice();

  const isLanguageSwitchVisible = !header.attributes?.hideLanguageSwitcher;

  const menuHeight = mobileNavHeight ? `calc(100dvh - ${mobileNavHeight}px)` : '100dvh';

  const buttons =
    header.attributes?.buttonsOnMobile && header.attributes?.buttonsOnMobile.length > 0
      ? header.attributes.buttonsOnMobile
      : header.attributes?.buttons;

  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 "
      enterTo="opacity-100 "
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 "
      leaveTo="opacity-0 "
    >
      <Popover.Panel
        style={{ top: `${mobileNavHeight}px` }}
        className={`flex flex-col justify-between z-nav absolute inset-x-0 transition transform origin-top-right xl:hidden`}
      >
        <div
          {...(isMobile && { style: { height: menuHeight } })}
          className={`flex flex-col justify-between rounded-b-lg shadow-lg ${
            dark ? 'bg-[#111827]' : HeaderTheme.navbar.background.light
          } w-screen sm:w-96 pt-7`}
        >
          <div className="overflow-y-auto w-full h-full">
            <nav className={`flex flex-col`}>
              <div className="">
                {header.attributes?.navbar.navbarItems?.data?.map((itemG) => (
                  <Menu
                    key={itemG.id}
                    nav={itemG}
                    handleMenuState={handleMenuState}
                    menuState={menuState}
                    desktop={false}
                    dark={dark}
                  />
                ))}
              </div>
            </nav>
          </div>

          <div className="p-5 w-full">
            <div className="pb-5 w-full px-5">
              {isLanguageSwitchVisible && <LocaleSwitch theme={'light'} pageData={pageData} />}
            </div>
            <div className={'max-w-sm'}>
              {buttons?.map((button, index) => {
                const isLastButton = header.attributes?.buttons.length === index + 1;

                return (
                  <div
                    key={button.id}
                    className={`w-full flex justify-center ${isLastButton && 'pt-3 mt-3 border-t border-grey-200'}`}
                  >
                    <Button data={button} extraClass="my-2 w-full">
                      {button.text}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
};

const Header = ({ header, pageData }: HeaderProps) => {
  const [menuState, setMenuState] = useState(initialMenuState);
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef<HTMLInputElement>(null);
  const mobileNavRef = useRef<HTMLElement>(null);
  const home = header?.attributes?.locale == 'en' ? '/' : `/${header?.attributes?.locale}`;

  const dark = !!((pageData.attributes?.options?.darkMode || header.attributes?.darkMode) && !sticky.isSticky);

  const handleMenuState = (menu: MenuState) => {
    setMenuState(menu);
  };

  const handleMouseLeaveMenuState = () => setMenuState(initialMenuState);

  const handleScroll = (elTopOffset: number, elHeight: number) => {
    if (window.scrollY < elTopOffset + elHeight || window.scrollY == 0) {
      setSticky({ isSticky: false, offset: 0 });
    } else {
      setSticky({ isSticky: true, offset: elHeight });
    }
  };

  useEffect(() => {
    const header = headerRef.current?.getBoundingClientRect();
    const handleScrollEvent = () => {
      if (header) {
        handleScroll(header.top, header.height);
      }
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [headerRef]);

  const defaultButton = header?.attributes?.buttons.find((button) => button.defaultButton);

  const isLanguageSwitchVisible = !header.attributes?.hideLanguageSwitcher;

  const mobileNavHeight = mobileNavRef.current?.getBoundingClientRect().height;

  return (
    <>
      {header?.attributes?.navbar && (
        <header onMouseLeave={handleMouseLeaveMenuState}>
          <nav
            ref={headerRef}
            className={`relative ${dark ? navbarStyles['navbar-dark'] : navbarStyles.navbar} hidden xl:block ${
              sticky.isSticky && navbarStyles.sticky
            }`}
          >
            <Popover className={`z-20 relative flex justify-center ${dark ? 'bg-[#111827]' : 'bg-white'}`}>
              <div className="flex max-w-navbar w-full items-center py-2.5 justify-between">
                <div className="space-x-10 h-full flex items-center justify-between">
                  <a href={home} className="flex">
                    <span className="sr-only">Holded</span>
                    <NextImage
                      classImg={'object-fit'}
                      media={dark ? header?.attributes?.logoDark?.data : header?.attributes?.logo.data}
                      width={96}
                      height={20}
                    />
                  </a>
                  <Popover.Group as="nav" className="flex justify-center items-center h-10 space-x-6">
                    {header?.attributes?.navbar?.navbarItems?.data?.map((itemG) => {
                      return (
                        <Menu
                          key={itemG.id}
                          nav={itemG}
                          handleMenuState={handleMenuState}
                          menuState={menuState}
                          desktop={true}
                          dark={dark}
                        />
                      );
                    })}
                  </Popover.Group>
                </div>
                <div className="flex items-center">
                  {isLanguageSwitchVisible && <LocaleSwitch theme={dark ? 'darkMode' : 'soft'} pageData={pageData} />}
                  {header?.attributes?.buttons.map((button) => {
                    const isDarkDefaultButton = dark && button.type === 'default';
                    const isDarkAlternativeButton = dark && button.type === 'alternative';

                    return (
                      <Button
                        key={button.id}
                        data={button}
                        extraClass={`mx-2 ${isDarkDefaultButton ? '[&]:text-[#679AF5]' : ''} ${
                          isDarkAlternativeButton ? '[&]:bg-transparent [&]:text-white' : ''
                        }`}
                      >
                        {button.text}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </Popover>
          </nav>
          <nav
            ref={mobileNavRef}
            className={`block xl:hidden ${sticky.isSticky && `${navbarStyles.sticky}`} overflow-none ${
              dark ? 'bg-[#111827]' : 'bg-white'
            } h-16 ${navbarStyles.navbar}`}
          >
            <Popover
              className={`${
                sticky.isSticky ? 'absolute' : 'overflow-auto'
              } py-3 px-5 xl:hidden flex items-center justify-between w-full`}
            >
              <a href={home} className={`flex items-center ${defaultButton ? 'h-3.5' : 'h-5'}`}>
                <span className="sr-only">Holded</span>
                <NextImage
                  classImg={'object-fit h-full w-auto'}
                  media={dark ? header?.attributes?.logoDark?.data : header?.attributes?.logo?.data}
                />
              </a>
              <div className="flex items-center">
                {defaultButton && (
                  <Button data={defaultButton} extraClass={'mr-3 text-sm'}>
                    {defaultButton.text}
                  </Button>
                )}
                <Popover.Group>
                  <Popover>
                    {({ open }) => {
                      if (open && !sticky.isSticky) {
                        setSticky({ isSticky: true, offset: 0 });
                      }
                      return (
                        <>
                          <Popover.Button
                            className={`${
                              dark ? 'bg-[#1F2937]' : 'bg-white'
                            } rounded-md p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none`}
                          >
                            <span className="sr-only">Open Menu</span>

                            {!open ? (
                              <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </Popover.Button>
                          {open && (
                            <HeaderMobile
                              header={header}
                              handleMenuState={handleMenuState}
                              menuState={menuState}
                              pageData={pageData}
                              mobileNavHeight={mobileNavHeight}
                              dark={false}
                            />
                          )}
                        </>
                      );
                    }}
                  </Popover>
                </Popover.Group>
              </div>
            </Popover>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;
