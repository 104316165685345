import dynamic from 'next/dynamic';
import { ComponentType, useEffect, useState } from 'react';

import { hasKey } from 'holded/lib/utils';
import { NavbarItem } from 'holded/modules/cms/domain/page';
import { MediaProps } from 'holded/shared/ui/components/Menu/MenuMedia';
import { SingleProps } from 'holded/shared/ui/components/Menu/MenuSingle';

export type MenuState = {
  name: string;
  state: boolean;
};

type MenuProps = {
  nav: NavbarItem;
  handleMenuState: (menu: MenuState) => void;
  desktop: boolean;
  menuState: MenuState;
  dark: boolean;
};

type MenuType = {
  media: ComponentType<MediaProps>;
  normal: ComponentType<SingleProps>;
};

const MenuUI: MenuType = {
  media: dynamic(() => import('holded/shared/ui/components/Menu/MenuMedia')),
  normal: dynamic(() => import('holded/shared/ui/components/Menu/MenuSingle')),
};
const Menu = ({ nav, handleMenuState, menuState, desktop, dark }: MenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuItemState = (menu: MenuState) => {
    handleMenuState(menu);
  };

  useEffect(() => {
    setShowMenu(menuState?.name == nav.attributes.name && menuState.state);
  }, [menuState]);

  if (!hasKey(MenuUI, nav.attributes.type)) return <></>;

  const MenuGroup = MenuUI[nav.attributes.type];

  return <MenuGroup nav={nav} handleMenuItem={handleMenuItemState} showMenu={showMenu} desktop={desktop} dark={dark} />;
};
export default Menu;
