import { sectionStyle } from 'holded/lib/styles';

export const HeaderTheme: HeaderThemeType = {
  navbar: {
    background: {
      light: sectionStyle.background.light,
    },
    menuTextColor: {
      light: sectionStyle.textColor.default,
    },
    submenuTextColor: {
      light: sectionStyle.textColor.grayInter,
    },
    footerDescription: {
      light: sectionStyle.textColor.secondary,
    },
  },
};

export type HeaderThemeType = {
  navbar: {
    background: {
      light: string;
    };
    menuTextColor: {
      light: string;
    };
    submenuTextColor: {
      light: string;
    };
    footerDescription: {
      light: string;
    };
  };
};
